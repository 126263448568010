<template>
  <validation-observer
    v-slot="{ handleSubmit }"
  >
    <b-form
      ref="myForm"
      class="p-2"
      @submit.enter.prevent="handleSubmit(addDonationCategory)"
    >
      <validation-provider
        #default="validationContext"
        name="Category Name"
        rules="required|min:3"
      >
        <b-form-group
          label="Category Name"
          label-for="cat_name"
        >
          <b-form-input
            id="cat_name"
            v-model="category.category_name"
            :state="getValidationState(validationContext)"
            autofocus
            placeholder="Category Name"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <validation-provider
        #default="validationContext"
        name="Category Status"
        rules="required"
      >
        <b-form-group
          label="Category Status"
          label-for="cat_status"
        >
          <b-form-select
            id="cat_status"
            v-model="category.status"
            :options="statusOptions"
            :state="getValidationState(validationContext)"
            value-field="value"
            text-field="text"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <b-form-group
        label-for="cat_name"
      >
        <b-form-file
          v-model="category.icon"
          accept="image/*"
          placeholder="Pick an Icon"
          prepend-icon="mdi-camera"
          @input="onChange"
        />
        <div
          v-if="preview"
          class="border p-2 mt-3"
        >
          <template>
            <img
              :src="preview"
              class="img-fluid"
              width="100"
            >
          </template>
        </div>
      </b-form-group>

      <b-form-group label="Color">
        <verte
          v-model="category.color"
          picker="wheel"
          model="hex"
          display="widget"
          :enable-alpha="false"
          :show-history="false"
        />
      </b-form-group>

      <!-- Form Actions -->
      <div class="d-flex mt-2">
        <b-button
          variant="primary"
          type="submit"
        >
          Add
        </b-button>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import Verte from 'verte'
import 'verte/dist/verte.css'
import formValidation from '@core/comp-functions/forms/form-validation'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import handleFormData from '@/common/compositions/common/handleFormData'

export default {
  name: 'AddDonationCategory',
  components: {
    Verte,
  },
  props: {
    statusOptions: {
      type: Array,
      default: () => {},
    },
  },

  data() {
    return {
      category: {},
      preview: '',
    }
  },
  setup() {
    // Form Validation
    const {
      getValidationState,
    } = formValidation()

    const { successfulOperationAlert, showErrors } = handleAlerts()

    const { setFormData, formData } = handleFormData()

    return {
      getValidationState,
      successfulOperationAlert,
      showErrors,

      setFormData,
      formData,
    }
  },
  methods: {
    addDonationCategory() {
      this.setFormData(this.category)
      this.$entities.post('internalops/donation-categories', this.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(() => {
        this.$bvModal.hide('add-modal')
        this.successfulOperationAlert('Donation category is added successfully')
        this.$emit('refresh')
      }).catch(err => {
        this.showErrors(err.response.data.errors)
      })
    },
    onChange(image) {
      const reader = new FileReader()
      reader.onload = e => {
        this.preview = e.target.result
      }
      reader.readAsDataURL(image)
    },
  },
}
</script>

<style lang="scss">

</style>
