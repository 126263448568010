<template>
  <b-row class="mt-2 justify-content-center">
    <b-col>
      <div class="text-right">
        <b-button
          v-b-modal.add-modal
          variant="primary"
          class="mb-1"
        >
          <span class="text-nowrap">Add Category</span>
        </b-button>
      </div>
      <b-card header="Search">
        <b-row>
          <b-col>
            <search
              placeholder="Name"
              :search-query.sync="searchQuery"
              @refresh="refreshTable"
            />
          </b-col>
          <b-col
            md="2"
            class="mt-1 mt-md-0"
          >
            <search-button @refresh="refreshTable" />
          </b-col>
        </b-row>
      </b-card>

      <b-card>
        <donation-categories-table
          ref="parentTable"
          v-bind="{
            getDonationCategories,
            pagination
          }"
          :category.sync="category"
        />
      </b-card>

      <pagination
        :total-rows="pagination.totalRows"
        :per-page="pagination.perPage"
        :current-page.sync="pagination.currentPage"
      />
    </b-col>

    <div class="d-flex align-items-center justify-content-end m-2">
      <!-- modal Add Language -->
      <b-modal
        id="add-modal"
        centered
        title="Add Category"
        hide-footer
      >
        <add-donation-category
          :status-options="statusOptions"
          @refresh="refreshTable"
        />
      </b-modal>

      <!-- Edit -->
      <b-modal
        id="edit-modal"
        centered
        title="Edit Category"
        hide-footer
      >
        <edit-donation-category
          v-bind="{category,statusOptions}"
          @refresh="refreshTable"
        />
      </b-modal>
    </div>
  </b-row>
</template>

<script>
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import Search from '@/common/components/common/Table/Search.vue'
import AddDonationCategory from '@/common/components/GeneralSettings/donation-categories/AddDonationCategory.vue'
import EditDonationCategory from '@/common/components/GeneralSettings/donation-categories/EditDonationCategory.vue'
import DonationCategoriesTable from '@/common/components/GeneralSettings/donation-categories/DonationCategoriesTable.vue'
import Pagination from '@/common/components/common/Table/Pagination.vue'

export default {
  components: {
    AddDonationCategory,
    EditDonationCategory,
    DonationCategoriesTable,
    Pagination,
    Search,
    SearchButton,
  },
  data() {
    return {
      category: {},
      pagination: {
        totalRows: 5,
        currentPage: 1,
        perPage: 10,
      },
      addCat: {
        category_name: null,
        status: null,
        icon: null,
        color: '#fff',
      },
      statusOptions: [
        { value: '1', text: 'Active' },
        { value: '0', text: 'Not Active' },
      ],
      imageData: null,
      catData: {
        category_name: '',
        status: '',
        icon: '',
      },
      searchQuery: '',
    }
  },
  methods: {
    getDonationCategories() {
      const promise = this.$entities.get(`internalops/donation-categories?page=${this.pagination.currentPage}`, {
        params: { name: this.searchQuery },
      })
      return promise.then(res => {
        const items = res.data.data.data
        this.pagination.perPage = res.data.data.per_page
        this.pagination.totalRows = res.data.data.total
        return items || []
      }).catch(() => [])
    },
    refreshTable() {
      this.$refs.parentTable.$refs.table.refresh()
    },
  },
}
</script>
