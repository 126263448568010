<template>
  <b-table
    ref="table"
    :items="getDonationCategories"
    :fields="tableColumns"
    :current-page="pagination.currentPage"
    :per-page="pagination.perPage"
    responsive="lg"
  >
    <template #cell(id)="{item}">
      <span> # {{ item.id }} </span>
    </template>
    <template #cell(name)="{item}">
      <b-media vertical-align="center">
        <template #aside>
          <b-avatar
            size="70"
            :src="$entitiesImagesURL + '/' + item.icon"
            :text="avatarText(item.category_name.slice(0,10))"
            rounded
          />
        </template>
        <span class="font-weight-bold d-block text-nowrap">
          {{ item.category_name }}
        </span>
      </b-media>
    </template>
    <template #cell(status)="{item}">
      <b-badge
        pill
        :variant="`light-${resolveUserStatusVariant(item.status)}`"
        class="text-capitalize"
      >
        <span> {{ resolveUserStatusValue(item.status) }}</span>
      </b-badge>
    </template>
    <!-- Column: Actions -->
    <template #cell(actions)="{item}">
      <b-dropdown
        variant="link"
        no-caret
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle text-body"
          />
        </template>
        <b-dropdown-item
          v-b-modal.edit-modal
          variant="outline-primary"
          @click="getCategoryById(item.id)"
        >
          <feather-icon icon="EditIcon" />
          <span> Edit </span>
        </b-dropdown-item>
        <b-dropdown-item @click="deleteRecord(deleteEndPoint,item.id)">
          <feather-icon icon="TrashIcon" />
          <span
            class="align-middle ml-50"
          >Delete</span>
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
</template>
<script>
import { avatarText } from '@core/utils/filter'
import deleteTag from '@/common/compositions/common/deleteRecord'

export default {
  name: 'DonationCategoriesTable',
  props: {
    getDonationCategories: { type: Function, default: () => [] },
    pagination: { type: Object, default: () => {} },
  },
  data() {
    return {
      tableColumns: [
        { key: 'id', label: '#', sortable: true },
        { key: 'name', sortable: true, searchable: true },
        { key: 'status', sortable: true, searchable: true },
        { key: 'actions' },
      ],
      deleteEndPoint: `${process.env.VUE_APP_ENTITIES_BASE_URL}/internalops/donation-categories/`,
    }
  },
  setup() {
    const { deleteRecord, table } = deleteTag()

    return {
      deleteRecord, table,
    }
  },
  methods: {
    avatarText,
    getCategoryById(id) {
      this.$entities.get(`internalops/donation-categories/${id}`).then(res => {
        this.$emit('update:category', res.data.data)
      })
    },
    resolveUserStatusValue(status) {
      return status ? 'Active' : 'Not Active'
    },
    resolveUserStatusVariant(status) {
      return status ? 'success' : 'warning'
    },
  },
}
</script>
<style lang="">

</style>
