var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{ref:"table",attrs:{"items":_vm.getDonationCategories,"fields":_vm.tableColumns,"current-page":_vm.pagination.currentPage,"per-page":_vm.pagination.perPage,"responsive":"lg"},scopedSlots:_vm._u([{key:"cell(id)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" # "+_vm._s(item.id)+" ")])]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"70","src":_vm.$entitiesImagesURL + '/' + item.icon,"text":_vm.avatarText(item.category_name.slice(0,10)),"rounded":""}})]},proxy:true}],null,true)},[_c('span',{staticClass:"font-weight-bold d-block text-nowrap"},[_vm._v(" "+_vm._s(item.category_name)+" ")])])]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{staticClass:"text-capitalize",attrs:{"pill":"","variant":("light-" + (_vm.resolveUserStatusVariant(item.status)))}},[_c('span',[_vm._v(" "+_vm._s(_vm.resolveUserStatusValue(item.status)))])])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-dropdown',{attrs:{"variant":"link","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{directives:[{name:"b-modal",rawName:"v-b-modal.edit-modal",modifiers:{"edit-modal":true}}],attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.getCategoryById(item.id)}}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_c('span',[_vm._v(" Edit ")])],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteRecord(_vm.deleteEndPoint,item.id)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Delete")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }