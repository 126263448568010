<template>
  <validation-observer
    v-slot="{ handleSubmit }"
  >
    <b-form
      ref="myForm"
      class="p-2"
      @submit.enter.prevent="handleSubmit(updateCategory)"
    >
      <validation-provider
        #default="validationContext"
        name="Category Name"
        rules="required|min:3"
      >
        <b-form-group
          label="Category Name"
          label-for="cat_name"
        >
          <b-form-input
            id="cat_name"
            v-model="category.category_name"
            autofocus
            placeholder="Category Name"
            :state="getValidationState(validationContext)"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <validation-provider
        #default="validationContext"
        name="Status"
        rules="required"
      >
        <b-form-group
          label="Category Status"
          label-for="cat_status"
        >
          <b-form-select
            id="cat_status"
            v-model="category.status"
            :options="statusOptions"
            value-field="value"
            text-field="text"
            :state="getValidationState(validationContext)"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <b-form-group
        label-for="cat_name"
      >
        <b-form-file
          ref="file"
          v-model="category.icon"
          name="icon"
          accept="image/*"
          placeholder="Pick an Icon"
          prepend-icon="mdi-camera"
          @input="onChange"
        />
        <div
          class="border p-2 mt-3"
        >
          <b-img
            v-if="preview"
            :src="preview"
            class="img-fluid"
          />
          <b-img
            v-else
            :src="$entitiesImagesURL+category.icon"
            class="img-fluid"
          />
        </div>
      </b-form-group>

      <b-form-group label="Color">
        <verte
          v-model="category.color"
          picker="wheel"
          model="hex"
          display="widget"
          :show-history="false"
          :enable-alpha="false"
        />
      </b-form-group>
      <!-- Form Actions -->
      <div class="d-flex mt-2">
        <b-button
          variant="primary"
          type="submit"
        >
          Save
        </b-button>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import Verte from 'verte'
import 'verte/dist/verte.css'
import formValidation from '@core/comp-functions/forms/form-validation'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import handleFormData from '@/common/compositions/common/handleFormData'

export default {
  name: 'EditDonationCategory',
  components: {
    Verte,
  },
  props: {
    category: {
      type: Object,
      default: () => {},
    },
    statusOptions: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      preview: null,
    }
  },
  setup() {
    const { successfulOperationAlert, showErrors } = handleAlerts()
    const {
      getValidationState,
    } = formValidation()
    const { setFormData, formData } = handleFormData()

    return {
      successfulOperationAlert, showErrors, setFormData, formData, getValidationState,
    }
  },
  methods: {
    updateCategory() {
      this.setFormData(this.category)
      this.$entities.post(`internalops/donation-categories/${this.category.id}?_method=PUT`, this.formData)
        .then(() => {
          this.$bvModal.hide('edit-modal')
          this.successfulOperationAlert('Donation category is edit successfully')
          this.$emit('refresh')
        }).catch(err => {
          this.showErrors(err.response.data.errors)
        })
    },
    onChange(image) {
      const reader = new FileReader()
      reader.onload = e => {
        this.preview = e.target.result
      }
      reader.readAsDataURL(image)
    },
  },
}
</script>

<style>

</style>
